export function WireBox({
  wireColor = "#DD00A9",
  fillColor = "",
  className,
}: {
  wireColor?: string
  fillColor?: string
  className?: string
}) {
  return (
    <svg
      viewBox="0 0 1280 598"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden
    >
      <g clipPath="url(#clip0_553_6139)">
        <path
          d="M0.416025 0.778308C58.7666 39.9475 106.026 128.253 106.25 198.531L0.416025 127.975V0.778308Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M212.918 142.444C212.694 212.424 165.435 237.716 107.084 199.085V71.8882L212.918 142.444Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M213.748 143L266.665 178.278C295.891 197.761 319.583 242.03 319.583 277.155C319.583 312.279 295.891 324.959 266.665 305.475L213.748 270.197V143Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M106.25 199.53C106.026 269.51 58.7666 294.803 0.416025 256.171V128.975L106.25 199.53Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M107.085 200.087C165.435 239.256 212.694 327.56 212.918 397.838C154.568 358.668 107.309 270.364 107.085 200.087Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M319.583 341.753V468.95C261.232 429.78 213.973 341.475 213.749 271.197L319.583 341.753Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M53.3333 419.647C24.1079 400.164 0.416025 355.895 0.416025 320.771C0.416025 285.646 24.1079 272.966 53.3333 292.45C82.5587 311.934 106.251 356.202 106.251 391.327C106.251 426.451 82.5587 439.131 53.3333 419.647Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M160.001 363.561L212.919 398.839V526.036L160.001 490.758C130.776 471.275 107.084 427.006 107.084 391.881C107.084 356.757 130.776 344.077 160.001 363.561Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M319.582 469.95C319.358 539.929 272.099 565.222 213.748 526.59V399.394L319.582 469.95Z"
          fill={fillColor}
          stroke={wireColor}
        />
      </g>
      <g clipPath="url(#clip1_553_6139)">
        <path
          d="M320.416 213.557C378.767 174.925 426.026 200.218 426.25 270.197L320.416 340.753V213.557Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M532.918 71.889C532.694 142.167 485.435 230.472 427.084 269.641V142.445L532.918 71.889Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M533.748 71.3335L586.665 36.0553C615.891 16.5717 639.583 29.2512 639.583 64.3758C639.583 99.5003 615.891 143.769 586.665 163.253L533.748 198.531V71.3335Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M426.251 271.197V398.393C367.9 437.024 320.64 411.732 320.417 341.752L426.251 271.197Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M532.918 200.086C532.694 270.364 485.435 358.669 427.084 397.838V270.642L532.918 200.086Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M533.749 199.531C592.099 160.901 639.358 186.193 639.582 256.171C581.232 294.802 533.973 269.51 533.749 199.531Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M373.333 561.869C344.108 581.353 320.416 568.673 320.416 533.549C320.416 498.424 344.108 454.156 373.333 434.672C402.559 415.188 426.251 427.868 426.251 462.992C426.251 498.117 402.559 542.386 373.333 561.869Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M532.918 328.284C532.694 398.561 485.435 486.866 427.084 526.036V398.839L532.918 328.284Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M586.665 292.45L639.583 257.172V384.369L586.665 419.647C557.44 439.131 533.748 426.451 533.748 391.327C533.748 356.202 557.44 311.933 586.665 292.45Z"
          fill={fillColor}
          stroke={wireColor}
        />
      </g>
      <g clipPath="url(#clip2_553_6139)">
        <path
          d="M640.416 0.778308C698.767 39.9475 746.026 128.253 746.25 198.531L640.416 127.975V0.778308Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M747.084 71.8882L852.918 142.444C852.694 212.424 805.435 237.716 747.084 199.085V71.8882Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M853.748 143L906.665 178.278C935.891 197.761 959.583 242.03 959.583 277.155C959.583 312.279 935.891 324.959 906.665 305.475L853.748 270.197V143Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M640.416 128.975L746.25 199.53C746.026 269.51 698.767 294.803 640.416 256.171V128.975Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M852.919 397.838C794.568 358.669 747.308 270.364 747.085 200.086L852.919 270.642V397.838Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M959.582 468.949C901.232 429.78 853.973 341.476 853.749 271.198C912.099 310.368 959.358 398.672 959.582 468.949Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M640.416 320.771C640.416 285.646 664.108 272.966 693.333 292.45C722.559 311.934 746.251 356.202 746.251 391.327C746.251 426.451 722.559 439.131 693.333 419.647C664.108 400.164 640.416 355.895 640.416 320.771Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M747.084 391.881C747.084 356.757 770.776 344.077 800.001 363.561L852.919 398.839V526.036L800.001 490.758C770.776 471.275 747.084 427.006 747.084 391.881Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M853.748 399.394L959.582 469.95C959.358 539.929 912.099 565.222 853.748 526.59V399.394Z"
          fill={fillColor}
          stroke={wireColor}
        />
      </g>
      <g clipPath="url(#clip3_553_6139)">
        <path
          d="M960.416 213.557C1018.77 174.925 1066.03 200.218 1066.25 270.197L960.416 340.753V213.557Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1067.08 142.445L1172.92 71.889C1172.69 142.167 1125.43 230.472 1067.08 269.641V142.445Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1173.75 71.3335L1226.67 36.0553C1255.89 16.5717 1279.58 29.2512 1279.58 64.3758C1279.58 99.5003 1255.89 143.769 1226.67 163.253L1173.75 198.531V71.3335Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M960.416 341.753L1066.25 271.197C1066.03 341.475 1018.77 429.78 960.416 468.949V341.753Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1172.92 327.282C1114.57 365.914 1067.31 340.621 1067.08 270.642L1172.92 200.086V327.282Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1279.58 256.171C1221.23 294.802 1173.97 269.51 1173.75 199.531C1232.1 160.901 1279.36 186.193 1279.58 256.171Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M960.416 533.549C960.416 498.424 984.108 454.156 1013.33 434.672C1042.56 415.188 1066.25 427.868 1066.25 462.992C1066.25 498.117 1042.56 542.386 1013.33 561.869C984.108 581.353 960.416 568.673 960.416 533.549Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1067.08 398.839L1172.92 328.284C1172.69 398.561 1125.43 486.866 1067.08 526.036V398.839Z"
          fill={fillColor}
          stroke={wireColor}
        />
        <path
          d="M1173.75 391.327C1173.75 356.202 1197.44 311.933 1226.67 292.45L1279.58 257.172V384.369L1226.67 419.647C1197.44 439.131 1173.75 426.451 1173.75 391.327Z"
          fill={fillColor}
          stroke={wireColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_553_6139">
          <rect
            width="384.592"
            height="384.592"
            fill="white"
            transform="matrix(0.83205 0.5547 0 1 0 0)"
          />
        </clipPath>
        <clipPath id="clip1_553_6139">
          <rect
            width="384.592"
            height="384.592"
            fill="white"
            transform="matrix(0.83205 -0.5547 0 1 320 213.333)"
          />
        </clipPath>
        <clipPath id="clip2_553_6139">
          <rect
            width="384.592"
            height="384.592"
            fill="white"
            transform="matrix(0.83205 0.5547 0 1 640 0)"
          />
        </clipPath>
        <clipPath id="clip3_553_6139">
          <rect
            width="384.592"
            height="384.592"
            fill="white"
            transform="matrix(0.83205 -0.5547 0 1 960 213.333)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
